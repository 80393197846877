<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <b>首页</b>
        </el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/foods/list' }">美食</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" class="el-form-self">
        <el-row>
          <el-col>
            <el-form-item label="类型:" prop="type">
              <div>{{ form.type==='1'?'传统美食': form.type==='2'?'网红美食': form.type==='3'?'名优产品': ''}}</div>
              <!--<template class="view-text" v-if="type==='1'">传统美食</template>
                  <template  class="view-text" v-if="type==='2'">网红美食</template>
                  <template class="view-text" v-if="type==='3'">特色小吃</template>
              <template class="view-text" v-if="type==='4'">热门美食</template>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="name">
              <div class="view-text">{{form.name}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
              <el-form-item label="简介:" prop="introduction">
                  <div class="view-text">{{form.introduction}}</div>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="所属商户" prop="merchantName">
              <div>{{form.merchantName}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="图片或者视频:" prop="videoName">
              <el-upload class="upload-demo" :action="uploadFileURL" :on-preview="handleImagePreview" :file-list="imageUrlArray"></el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="列表缩略图片:" prop="indexImageUrl" :disabled="true">
              <el-upload
                class="companyNewsImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false"
              >
                <img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage" />
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="内容:">
              <Editor id="tinymce" v-model="form.content" :init="init" :disabled="true"></Editor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="置顶:" prop="isTop">
              <div class="view-text">{{form.isTop==0?'是':'否'}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="推荐:" prop="recommended">
              <div class="view-text">{{form.recommended==0?'是':'否'}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="阅读次数:" prop="readNumber">
              <div class="view-text">{{form.readNumber}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="点赞人数:" prop="star">
              <div class="view-text">{{form.star}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="状态:" prop="status">
              <div class="view-text">{{form.status==0?'已发布':'草稿'}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="发布时间:" prop="formatCreateTime">
              <div class="view-text">{{form.formatCreateTime}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="上次修改时间:" prop="publishTime">
              <div class="view-text">{{ form.formatUpdateTime || '暂无更改' }}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="发布人:" prop="publisher" v-if="this.form.status==0">
              <div class="view-text">{{form.publisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="保存人:" prop="publisher" v-if="this.form.status==1">
              <div class="view-text">{{form.publisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <el-dialog :visible.sync="imageUrlDialogVisible">
        <video v-if="uploadType==='video/mp4'" v-for="(video,index) in imageUrlList" :key="index" width="100%" height="100%" :src="downloadFileURL+video+'/'" controls></video>
        <img v-if="uploadType!=='video/mp4'" v-for="(image,index) in imageUrlList" :key="index" width="180px" height="200px" :src="downloadFileURL+image+'/'" alt/>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import { uploadFileURL, downloadFileURL } from "../../../api/system-api";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
import "tinymce/plugins/code";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/importcss";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/save";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/codesample";
import "tinymce/plugins/table";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/anchor";
import "tinymce/plugins/toc";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/advlist";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/help";
import "tinymce/plugins/charmap";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/lists";
import "tinymce/plugins/indent2em"
import "tinymce/icons/default/icons";
import { reqGetFoodsOne } from "../../../api/foods-api";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      imageUrlDialogVisible: false,
      uploadFileURL: uploadFileURL,
      indexImageUrl: "",
      imageUrlArray: [],
      imageUrlList: [],
      editor: "",
      uploadType: "",
      downloadFileURL: downloadFileURL,
      editPath: "/foods/list",
      defaultMsg: "",
      form: {
        imageUrl: "",
        name: "",
        videoUrl: "",
        content: "",
        status: "",
        publisher: "",
        //systemUser: '',
        videoName: "",
        //numberOrder:null,
        isTop: "1",
        type: "",
        recommended: "1",
        indexImageUrl: "",
        createTime: "",
        formatUpdateTime: "",
        formatCreateTime: "",
      },
      init: {
        language_url: "/static/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/static/tinymce/skins/ui/oxide",
        content_css: "/static/tinymce/skins/content/default/content.css",
        height: 400,
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        plugins:
          "code indent2em print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image media link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars",
        toolbar:
          "undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media  pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
      },
    };
  },
  methods: {
    handleImagePreview(file) {
      //console.log(this.imageUrlList);
      this.imageUrlDialogVisible = true;
    },
    reqGetFoodsOne(id) {
      let that = this;
      reqGetFoodsOne({ id: id }).then((res) => {
        if (res.data.data.indexImageUrl) {
          this.indexImageUrl =
            downloadFileURL + res.data.data.indexImageUrl + "/";
        } else {
          this.indexImageUrl = "";
        }
        this.form = Object.assign({}, res.data.data);
        if (res.data.code === 1) {
          that.form = res.data.data;
          if (that.form.videoUrl) {
            that.videoUrlList = [
              {
                url: downloadFileURL + that.form.videoUrl + "/",
                name: that.form.videoName,
              },
            ];
          }
          that.imageUrlList = [];
          if (that.form.imageUrl) {
            that.imageUrlList = that.form.imageUrl.split(",");
            that.imageUrlList.forEach(function (obj, i) {
              that.imageUrlArray.push({
                url: downloadFileURL + obj + "/",
                name: obj,
              });
              if (obj.toLowerCase().indexOf(".mp4") !== -1) {
                that.uploadType = "video/mp4";
                that.imageLimit = 1;
              }
            });
          }
          //console.log(that.imageUrlList);
        } else {
          that.$message({
            message: "查询失败",
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    tinymce.init({});
    var row = this.$route.query;
    var id = row.id;
    this.reqGetFoodsOne(id);
  },
};
</script>
